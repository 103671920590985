.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pac-container:after {
  content: none !important;
}

.gm-style-cc {
  display: none !important;
}

.gm-style a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.fc-highlight {
  background: rgb(1, 253, 220) !important;
}

.fc-day-disabled {
  visibility: hidden;
}

.filledWithIcon .react-date-picker__wrapper {
  border: none;
}

.error-template {
  padding: 40px 15px;
  text-align: center;
}

.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}

.error-actions .btn {
  margin-right: 10px;
}

td {
  border: 1px solid black;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

table {
  table-layout: fixed;
  width: 100%;
  max-width: 1000px;
  border-collapse: collapse;
}

.day,
.dow,
.dummy-day {
  display: inline-block;
  width: 12.7864%;
  text-align: center;
  margin-right: 1.5%;
}


.close span {
  display: none;
}

.customMarker {
  position: absolute;
  cursor: pointer;
  background: #424242;
  width: 100px;
  height: 100px;
  /* -width/2 */
  margin-left: -50px;
  /* -height + arrow */
  margin-top: -110px;
  border-radius: 10px;
  padding: 0px;
}

.customMarker:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 40px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #424242 transparent;
  display: block;
  width: 0;
}

.customMarker img {
  width: 90px;
  height: 90px;
  margin: 5px;
  border-radius: 10px;
}

.pop-outer {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pop-inner {
  background-color: #fff;
  width: 500px;
  height: 300px;
  padding: 25px;
  margin: 0% auto;
}














.menu-nav {

  display: flex;
  justify-content: space-between;
}

.menu-item {
  color: #FCC;
  padding: 3px;
}

.three-dots:after {
  cursor: pointer;
  color: #5983f0;
  content: '\2807';
  font-size: 20px;
  padding: 0 5px;
}

a {
  text-decoration: none;
  color: white;
}

a div {
  padding: 2px;
}

.dropdown {
  position: absolute;
  right: 10px;
  outline: none;
  opacity: 0;
  z-index: -1;
  max-height: 0;
  /* transition: opacity 0.1s, z-index 0.1s, max-height: 5s; */
}

.dropdown-container:focus {
  outline: none;
}

.dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  /* transition: opacity 0.2s, z-index 0.2s, max-height: 0.2s; */
}

/* :root {
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}
@media (min-width: 600px) {
  :root {
    --rsbs-max-w: 43%;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
} */

.userIconMarker {
  /* the marker div */
  position:absolute;
  cursor:pointer;
  background:#424242;
  width:100px;
  height:100px;
  /* -width/2 */
  margin-left:-50px;
  /* -height + arrow */
  margin-top:-110px;
  border-radius:10px;
  padding:0px;
}
.userIconMarker:after {
  content:"";
  position: absolute;
  bottom: -10px;
  left: 40px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #424242 transparent;
  display: block;
  width: 0;
}
.userIconMarker img {
  width:90px;
  height:90px;
  margin:5px;
  border-radius:10px;
}


@media (max-width: 600px) {
  .Toastify__toast-container {
    right: 0!important;
    left: auto!important;
    width: 95vw!important;
  }  
} 
